import React from "react";
import cn from "classnames";
import * as styles from "./Buttons.module.scss";

type Props = {
  end?: boolean;
  className?: string;
};
export const Buttons: React.FC<Props> = ({ end, className, children }) => {
  return (
    <div className={cn(styles.buttons, className, !!end && styles.end)}>
      {children}
    </div>
  );
};
