import React from "react";
import { graphql } from "gatsby";
import { Section, SectionTitle } from "../Section";
import { Link } from "../Link";
import { getCaseStudyUrl, getCaseStudiesUrl } from "../../utils/urls";
import { MdxImageSharp } from "../../types/images";
import { Buttons } from "../Buttons";
import { Card, CardBody, CardGrid, CardImage, CardTitle } from "../Card";
import { Image } from "../Image";
import cardTitleArrow from "../../images/card_title_arrow.svg";
import * as styles from "./MdxCaseStudies.module.scss";
import { Button } from "../Button";
import { Technologies } from "../../utils/tech";

export type MdxCaseStudiesData = {
  nodes: {
    id: string;
    excerpt: string;
    frontmatter: {
      featured: MdxImageSharp;
      title: string;
      description: string;
      tech: string[];
    };
    fields: {
      slug: string;
    };
  }[];
};

export const mdxCaseStudyQuery = graphql`
  fragment CaseStudiesData on MdxConnection {
    nodes {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        featured {
          ...CardImageData
        }
        title
        description
        tech
      }
      fields {
        slug
      }
    }
  }
`;

type Props = {
  caseStudiesData: MdxCaseStudiesData;
};

export const MdxCaseStudies: React.FC<Props> = ({ caseStudiesData }) => {
  const nodes = caseStudiesData.nodes;

  return (
    <CardGrid className={styles.case_studies_list}>
      {nodes.map(({ id, excerpt, frontmatter, fields }) => {
        if (!frontmatter || !fields) return null;

        const url = getCaseStudyUrl(fields?.slug);

        return (
          <Card key={id} className={styles.case_study}>
            <CardImage>
              <Link to={url}>
                <Image image={frontmatter.featured} alt={frontmatter.title} />
              </Link>
            </CardImage>
            <TechnologiesList technologies={frontmatter.tech} />
            <CardTitle variant="big" className={styles.case_study_title}>
              <Link to={url} className={styles.case_study_title_link}>
                {frontmatter.title}{" "}
                <img
                  className={styles.case_study_title_arrow}
                  src={cardTitleArrow}
                  alt="card_title_arrow"
                />
              </Link>
            </CardTitle>
            <CardBody className={styles.case_study_body}>
              {frontmatter.description || excerpt || ""}
            </CardBody>
          </Card>
        );
      })}
    </CardGrid>
  );
};

interface ITechnologies {
  technologies: string[];
}

export const TechnologiesList: React.FC<ITechnologies> = ({ technologies }) => {
  return (
    <div className={styles.technologies_list}>
      {technologies.map((technology) => {
        const image =
          Technologies[technology as keyof typeof Technologies]?.image;

        return (
          <div className={styles.technology}>
            {image && <img className={styles.technology_image} src={image} />}
            <div className={styles.technology_name}>{technology}</div>
          </div>
        );
      })}
    </div>
  );
};

export const MdxCaseStudiesSection: React.FC<Props> = ({ caseStudiesData }) => {
  if (!caseStudiesData?.nodes?.length) return null;

  return (
    <section className={styles.section_case_studies}>
      <Section className={styles.section_case_studies_inner}>
        <SectionTitle className={styles.title}>
          <Link to={getCaseStudiesUrl()}>Case Studies</Link>
        </SectionTitle>
        <MdxCaseStudies caseStudiesData={caseStudiesData} />
        <Buttons>
          <Button to={getCaseStudiesUrl()}>View More</Button>
        </Buttons>
      </Section>
    </section>
  );
};
