import React from "react";
import cn from "classnames";
import * as styles from "./PageHeader.module.scss";

type PageHeaderProps = {
  className?: string;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  className,
  children,
}) => {
  return <div className={cn(styles.header, className)}>{children}</div>;
};

export const PageTitle: React.FC = ({ children }) => {
  return <h1 className={styles.title}>{children}</h1>;
};
