// extracted by mini-css-extract-plugin
export var section_case_studies = "MdxCaseStudies-module--section_case_studies--1x0bg";
export var section_case_studies_inner = "MdxCaseStudies-module--section_case_studies_inner--1HNR5";
export var title = "MdxCaseStudies-module--title--17UxX";
export var case_study = "MdxCaseStudies-module--case_study--2Ex6A";
export var case_study_title = "MdxCaseStudies-module--case_study_title--KsnQ-";
export var case_studies_list = "MdxCaseStudies-module--case_studies_list--1wZmh";
export var case_study_title_arrow = "MdxCaseStudies-module--case_study_title_arrow--2fQIR";
export var case_study_body = "MdxCaseStudies-module--case_study_body--3xxw1";
export var case_study_title_link = "MdxCaseStudies-module--case_study_title_link--1yHqf";
export var technologies_list = "MdxCaseStudies-module--technologies_list--8M_Fb";
export var technology = "MdxCaseStudies-module--technology--1Rfbu";
export var technology_name = "MdxCaseStudies-module--technology_name--1lI8X";
export var technology_image = "MdxCaseStudies-module--technology_image--3Y5ld";