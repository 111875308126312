import React from "react";
import cn from "classnames";
import { graphql } from "gatsby";
import * as styles from "./Card.module.scss";
import { useInView } from "react-intersection-observer";

type CardGridProps = {
  className?: string;
};

export const CardGrid: React.FC<CardGridProps> = ({ className, children }) => {
  return <div className={cn(styles.grid, className)}>{children}</div>;
};

type CardListProps = {
  className?: string;
};

export const CardList: React.FC<CardListProps> = ({ className, children }) => {
  return <div className={cn(styles.list, className)}>{children}</div>;
};

type CardProps = {
  className?: string;
};

export const Card: React.FC<CardProps> = ({ className, children }) => {
  const [ref, inView] = useInView();

  return (
    <div
      ref={ref}
      className={cn(styles.card, className, "show-up", !!inView && "visible")}
    >
      {children}
    </div>
  );
};

type CardImageProps = {
  className?: string;
};

export const CardImage: React.FC<CardImageProps> = ({
  className,
  children,
}) => {
  return <div className={cn(styles.image, className)}>{children}</div>;
};

type CardTitleProps = {
  variant?: "normal" | "big";
  className?: string;
};

export const CardTitle: React.FC<CardTitleProps> = ({
  className,
  variant,
  children,
}) => {
  return (
    <h3
      className={cn(styles.title, variant === "big" && styles.big, className)}
    >
      {children}
    </h3>
  );
};

type CardMiscProps = {
  className?: string;
};

export const CardInner: React.FC<CardMiscProps> = ({ className, children }) => {
  return <div className={cn(styles.inner, className)}>{children}</div>;
};

type CardBodyProps = {
  className?: string;
};

export const CardBody: React.FC<CardBodyProps> = ({ className, children }) => {
  return <div className={cn(styles.body, className)}>{children}</div>;
};

export const imageGridItemQuery = graphql`
  fragment CardImageData on File {
    childImageSharp {
      gatsbyImageData(
        width: 567
        height: 320
        quality: 90
        transformOptions: { fit: COVER, cropFocus: ENTROPY }
        formats: [AUTO, WEBP]
      )
    }
  }

  fragment CardListImageData on File {
    childImageSharp {
      gatsbyImageData(width: 544, quality: 90, formats: [AUTO, WEBP])
    }
  }
`;
