import React from "react";
import { Layout } from "../components/Layout";
import { graphql, useStaticQuery } from "gatsby";
import { Seo } from "../components/Seo";
import {
  MdxCaseStudies,
  MdxCaseStudiesData,
} from "../components/CaseStudies/MdxCaseStudies";
import { Container } from "../components/Section";
import { PageHeader, PageTitle } from "../components/PageHeader";

const Page = () => {
  const data = useStaticQuery<{ allMdx: MdxCaseStudiesData }>(graphql`
    query {
      allMdx(
        filter: { fields: { collection: { eq: "cases" } } }
        sort: { fields: frontmatter___priority }
      ) {
        ...CaseStudiesData
      }
    }
  `);

  return (
    <Layout>
      <Seo
        ogFullTitle
        title="Case Studies"
        description="Learn how we developed successful web applications for our clients."
      />
      <Container>
        <PageHeader>
          <PageTitle>Case Studies</PageTitle>
        </PageHeader>
        <MdxCaseStudies caseStudiesData={data.allMdx} />
      </Container>
    </Layout>
  );
};

export default Page;
